import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CREATE_SUCCESS_STORY, DELETE_SUCCESS_STORY, UPDATE_SUCCESS_STORY } from '../../graphql/mutations/mutations';
import { SUCCESS_STORIES, SUCCESS_STORY } from '../../graphql/queries/queries';
import { CreateSuccessStoryInput, DeleteSuccessStoryInput, GetSuccessStoryInput, PaginationOptions, SuccessStories, UpdateSuccessStoryInput } from '../../graphql/types/types';

@Injectable({
  providedIn: 'root'
})
export class SucessStoryService {

  constructor(private apollo: Apollo) { }

  listAllSuccessStories(Obj: PaginationOptions) {
    return this.apollo.query<SuccessStories>({
      query: SUCCESS_STORIES,
      variables: { paginationOptions: Obj }
    })
  }

  listSuccessStory(obj: GetSuccessStoryInput){
    return this.apollo.query<SuccessStories>({
      query: SUCCESS_STORY,
      variables: { getSuccessStoryInput: obj}
    })
  }

  addSuccessStory(Obj: CreateSuccessStoryInput) {
    return this.apollo.mutate<[SuccessStories]>({
      mutation: CREATE_SUCCESS_STORY,
      variables: { createSuccessStoryInput : Obj },
      context: { useMultipart: true }
    })
  }

  updateSuccessStory(Obj: UpdateSuccessStoryInput){
    return this.apollo.mutate<[SuccessStories]>({
      mutation: UPDATE_SUCCESS_STORY,
      variables: { updateSuccessStoryInput : Obj },
      context: { useMultipart: true }
    })
  }

  public deleteSuccessStory(Obj: DeleteSuccessStoryInput) {
    return this.apollo.mutate<any>({
      mutation: DELETE_SUCCESS_STORY,
      variables: { deleteSuccessStoryInput: Obj }
    })
  }
}
